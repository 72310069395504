import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, title, postStructuredData }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata.title
  const siteUrl = site.siteMetadata.siteUrl || `http://localhost:8000`

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: siteUrl,
    inLanguage: "pt-br",
    mainEntityOfPage: siteUrl,
    description: metaDescription,
    name: site.siteMetadata.title,
    author: {
      "@type": "Organization",
      "@id": site.siteMetadata.author,
    },
    image: {
      "@type": "ImageObject",
      url: "https://puragrid.ivaengenharia.com.br/logo.png",
    },
  }
  // const orgStructuredData = {
  //   "@context": "https://schema.org",
  //   "@type": "LocalBusiness",
  //   url: "https://puragrid.ivaengenharia.com.br",
  //   name: "PuraGrid Solar",
  //   contactPoint: {
  //     "@type": "ContactPoint",
  //     telephone: "Phone Number",
  //     contactType: "Atendimento",
  //   },
  //   telephone: "Phone Number",
  //   email: "atendimento@puragrid.com.br",
  //   hasMap: "Maps link",
  //   address: {
  //     "@type": "PostalAddress",
  //     addressLocality: "Pelotas",
  //     addressRegion: "Rio Grande do Sul",
  //     postalCode: "CEP",
  //     streetAddress: "Endereço",
  //   },
  //   sameAs: [
  //     "Social links,
  //   ],
  //   openingHours: "Mo,Tu,We,Th,Fr, 09:00-12:00,14:00-18:00",
  //   image: {
  //     "@type": "ImageObject",
  //     url: "https://puragrid.ivaengenharia.com.br/logo.png",
  //   },
  // }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      {/* <script type="application/ld+json">
        {JSON.stringify(orgStructuredData)}
      </script> */}
      {postStructuredData && (
        <script type="application/ld+json">
          {JSON.stringify(postStructuredData)}
        </script>
      )}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `pt-br`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
