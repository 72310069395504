import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => {
  return (
    <footer className="bg-dark-grey">
      <div className="padding-top medium-padding-bottom">
        <Container>
          <Row>
            <Col
              xs={12}
              md={3}
              className="align-self-center mb-3 mb-md-0 text-center text-md-left"
            >
              <StaticImage
                src="../images/logo_branco.png"
                height={60}
                alt="Logo"
                placeholder="tracedSVG"
              />
            </Col>
            <Col
              className="h-100 my-3 mt-y-0 text-center text-md-left"
              xs={12}
              md={3}
            >
              <h3>Matriz</h3>
              <p>Rua Senador Mendonça, 225 - Centro</p>
              <p>Pelotas/RS</p>
              <p>96015-200</p>
            </Col>
            {/* <Col
              className="my-3 mt-y-0 text-center text-md-left"
              xs={12}
              md={3}
            >
              <h3>Unidades Regionais</h3>
              <p>Região - Contato</p>
              <p>Região - Contato</p>
              <p>Região - Contato</p>
              <p>Região - Contato</p>
              <p>Região - Contato</p>
            </Col>
            <Col className="align-self-center mt-3 mt-md-0" xs={12} md={3}>
              <Row>
                <Col className="text-center">
                  <StaticImage
                    src="../images/instagram.png"
                    height={40}
                    alt="Instagram"
                    placeholder="tracedSVG"
                  />
                </Col>
                <Col className="text-center">
                  <StaticImage
                    src="../images/facebook.png"
                    height={40}
                    alt="Facebook"
                    placeholder="tracedSVG"
                  />
                </Col>
                <Col className="text-center">
                  <StaticImage
                    src="../images/youtube.png"
                    height={40}
                    alt="Youtube"
                    placeholder="tracedSVG"
                  />
                </Col>
                <Col className="text-center">
                  <StaticImage
                    src="../images/email.png"
                    height={40}
                    alt="Email"
                    placeholder="tracedSVG"
                  />
                </Col>
              </Row>
            </Col> */}
          </Row>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
