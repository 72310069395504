import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Navbar, Nav, Container } from "react-bootstrap"

const Header = () => {
  return (
    <>
      <div className="header">
        <Container>
          <Navbar variant="light" expand="lg" className="px-0">
            <Link
              to="/"
              className="navbar-brand p-0 py-1"
              activeClassName="active"
            >
              <StaticImage
                src="../images/logo.png"
                height={43}
                alt="Logo"
                placeholder="tracedSVG"
              />
            </Link>
            <Navbar.Toggle aria-controls="main-navbar" />
            <Navbar.Collapse id="main-navbar">
              <Nav className="ml-auto align-self-stretch align-items-center">
                <Link
                  to="/sistemasfotovoltaicos"
                  className="nav-link"
                  activeClassName="active"
                >
                  Sistemas Fotovoltaicos
                </Link>
                {/* <Link
                  to="/videos"
                  className="nav-link"
                  activeClassName="active"
                >
                  Vídeos
                </Link> */}
                <Link
                  to="/orcamento"
                  className="nav-link"
                  activeClassName="active"
                >
                  Orçamento
                </Link>
                {/* <Link to="/blog" className="nav-link" activeClassName="active">
                  Blog
                </Link> */}
                <Link
                  to="/contato"
                  className="nav-link"
                  activeClassName="active"
                >
                  Contatos
                </Link>
                {/* <Link
                  to="/sejaumfranqueado"
                  className="nav-link highlight"
                  activeClassName="active"
                >
                  Seja um franqueado
                </Link> */}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>
    </>
  )
}

export default Header
